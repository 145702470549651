<template>
  <MembersTable />
</template>
<script>
import MembersTable from './MembersTable';

export default {
  components: {
    MembersTable
  },
  props: {}
};
</script>